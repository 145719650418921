var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-row',{attrs:{"gutter":24,"type":"flex"}},[_c('a-col',{staticClass:"mb-24",attrs:{"span":24}},[_c('a-card',{staticClass:"header-solid h-full",attrs:{"bordered":false,"bodyStyle":{ paddingTop: 0 }},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h5',{staticClass:"font-semibold text-center"},[_vm._v(" "+_vm._s(_vm.feature.title)+" Update ")])]},proxy:true}])},[_c('a-form',{staticClass:"feature-update-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-row',{attrs:{"gutter":24,"type":"flex"}},[_c('a-col',{staticClass:"mb-24",attrs:{"span":24,"md":24}},[_c('a-form-item',{staticClass:"mb-10"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'name',
                  {
                    initialValue: _vm.feature.name,
                    rules: [
                      {
                        required: true,
                        message: 'Please input name!',
                      } ],
                  } ]),expression:"[\n                  'name',\n                  {\n                    initialValue: feature.name,\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Please input name!',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"placeholder":"Name"}})],1),_c('a-form-item',{staticClass:"mb-10"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'title',
                  {
                    initialValue: _vm.feature.title,
                    rules: [
                      {
                        required: true,
                        message: 'Please input title!',
                      } ],
                  } ]),expression:"[\n                  'title',\n                  {\n                    initialValue: feature.title,\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Please input title!',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"placeholder":"Title"}})],1),_c('a-form-item',{staticClass:"mb-10"},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'body',
                  {
                    initialValue: _vm.feature.body,
                    rules: [
                      {
                        required: true,
                        message: 'Please input body!'
                      } ],
                  } ]),expression:"[\n                  'body',\n                  {\n                    initialValue: feature.body,\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Please input body!'\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"placeholder":"Body"}})],1),_c('a-form-item',{staticClass:"mb-10"},[_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"file-input"},[_c('label',[_vm._v(" Image")]),_c('a-input',{attrs:{"type":"file"},on:{"change":function($event){return _vm.imageUploadChange($event)}}})],1),_c('img',{attrs:{"src":_vm.feature.image,"alt":"image","width":"100","height":"100"}})])]),_c('a-form-item',[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'parent_id',
                  {
                    initialValue: _vm.feature.parent_id,
                    rules: [
                      {
                        required: false,
                        message: 'Please select parent feature!',
                      } ],
                  } ]),expression:"[\n                  'parent_id',\n                  {\n                    initialValue: feature.parent_id,\n                    rules: [\n                      {\n                        required: false,\n                        message: 'Please select parent feature!',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"placeholder":"Select Parent Feature"}},_vm._l((_vm.featureList),function(value,id){return _c('a-select-option',{key:value.id},[_vm._v(" "+_vm._s(value.name)+" ")])}),1)],1)],1)],1),_c('a-form-item',[_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","block":"","html-type":"submit"}},[(_vm.confirmLoading)?_c('a-spin'):_vm._e(),_vm._v(" Update ")],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }