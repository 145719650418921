<template>
  <a-row :gutter="24" type="flex">
    <a-col :span="24" class="mb-24">
      <a-card
        :bordered="false"
        class="header-solid h-full"
        :bodyStyle="{ paddingTop: 0 }"
      >
        <template #title>
          <h5 class="font-semibold text-center">
            {{ feature.title }} Update
          </h5>
        </template>
        <a-form
          id="components-form-demo-normal-login"
          :form="form"
          class="feature-update-form"
          @submit="handleSubmit"
        >
          <a-row :gutter="24" type="flex">
            <a-col :span="24" :md="24" class="mb-24">
              <a-form-item class="mb-10">
                <a-input
                  v-decorator="[
                    'name',
                    {
                      initialValue: feature.name,
                      rules: [
                        {
                          required: true,
                          message: 'Please input name!',
                        },
                      ],
                    },
                  ]"
                  placeholder="Name"
                >
                </a-input>
              </a-form-item>
              <a-form-item class="mb-10">
                <a-input
                  v-decorator="[
                    'title',
                    {
                      initialValue: feature.title,
                      rules: [
                        {
                          required: true,
                          message: 'Please input title!',
                        },
                      ],
                    },
                  ]"
                  placeholder="Title"
                >
                </a-input>
              </a-form-item>
              <a-form-item class="mb-10">
                <a-textarea
                  v-decorator="[
                    'body',
                    {
                      initialValue: feature.body,
                      rules: [
                        {
                          required: true,
                          message: 'Please input body!'
                        },
                      ],
                    },
                  ]"
                  placeholder="Body"
                >
                </a-textarea>
              </a-form-item>
              <a-form-item class="mb-10">
                <div style="display: flex">
                  <div class="file-input">
                    <label> Image</label>
                    <a-input type="file" @change="imageUploadChange($event)">
                    </a-input>
                  </div>
                  <img
                    :src="feature.image"
                    alt="image"
                    width="100"
                    height="100"
                  />
                </div>
              </a-form-item>
              <a-form-item>
                <a-select
                  placeholder="Select Parent Feature"
                  v-decorator="[
                    'parent_id',
                    {
                      initialValue: feature.parent_id,
                      rules: [
                        {
                          required: false,
                          message: 'Please select parent feature!',
                        },
                      ],
                    },
                  ]"
                >
                  <a-select-option v-for="(value, id) in featureList" :key="value.id">
                    {{ value.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
          <a-form-item>
            <a-button
              type="primary"
              block
              html-type="submit"
              class="login-form-button"
            >
              <a-spin v-if="confirmLoading" />
              Update
            </a-button>
          </a-form-item>
        </a-form>
      </a-card>
    </a-col>
  </a-row>
</template>

<script>
import { sendRequest } from "@/http/axios.method";
import event from "ant-design-vue/lib/_util/css-animation/Event";

export default {
  name: "FeatureUpdate",
  computed: {
    event() {
      return event;
    },
  },
  data() {
    return {
      config: {
        Authorization: "Bearer " + this.$store.state.authUser.token,
      },
      featureId: this.$route.query.id,
      confirmLoading: false,
      feature: [],
      featureImage: "",
      isActive: false,
      message: "",
      featureList: [],
    };
  },
  beforeCreate() {
    // Creates the form and adds to it component's "form" property.
    this.form = this.$form.createForm(this, { name: "feature_update" });
  },
  created() {
    this.getFeatureDetails();
    this.getParentFeatureList();
  },
  methods: {
    getParentFeatureList() {
      sendRequest("get", "admin/parent_features", {}, this.config)
        .then((response) => {
          if (response.success) {
            let data = response.data;
            this.featureList = data.map(function (content, label) {
              return {
                id: content.id,
                name: content.name,
              };
            });
          } else {
            if (response.status_code === 401) {
              this.$store.dispatch("logout");
              this.$router.push("/login");
            }
          }
        })
        .catch(() => {})
        .finally(() => {});
    },
    getFeatureDetails() {
      sendRequest("get", "admin/feature/" + this.featureId , {}, this.config)
        .then((response) => {
          if (response.success) {
            this.feature = response.data;
          } else {
            if (response.status_code === 401) {
              this.$store.dispatch("logout");
              this.$router.push("/login");
            }
          }
        })
        .catch(() => {})
        .finally(() => {});
    },
    imageUploadChange(event, type) {
      this.featureImage = event.target.files[0];
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.confirmLoading = true;
          let form_data = new FormData();
          let configHeader = {
            "content-type": "multipart/form-data",
            Authorization: "Bearer " + this.$store.state.authUser.token,
          };
          let data = {
            id: this.featureId,
            name: values.name,
            title: values.title,
            body: values.body,
            parent_id: values.parent_id? values.parent_id : "",

       
         
          };
          for (let key in data) {
            form_data.append(key, data[key]);
          }
          form_data.append("image", this.featureImage);
          sendRequest("post", "admin/feature/" + this.featureId, form_data, configHeader)
            .then((response) => {
              if (response.success) {
                this.confirmLoading = false;
                this.$store.commit("setMessage", response.message);
                this.$router.push("/features");
              } else {
                if (response.status_code === 401) {
                  this.$store.dispatch("logout");
                  this.$router.push("/login");
                }
              }
            })
            .catch(() => {
              this.confirmLoading = false;
            })
            .finally(() => {
              this.confirmLoading = false;
            });
        }
      });
    },
  },
};
</script>
